.orgImg{
    width: 80%;
}

@media screen and (max-width: 600px) {
    .titleCont  {
        justify-content: center;
    }
    .orgImg{
        width: 100%;
    }
}
.ubicacion{
    background-color: #242626;
}
.mapaHome{
    width: 100%;
    height: 400px;
    border-radius: 10px;
}

.ubicacionTxt{
    color: white;
    text-align: justify;
    font-size: 20px;
}
.ubiTxtCont{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ubiTitle{
    width: 100%;
    justify-content: left;
}
