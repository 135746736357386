.ejesAccion{
    background-color: #45b5b7;
    padding: 30px;
}
.tituloSeccVerde{
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 26px;
    border-bottom: 5px solid white;
    display: inline;
    text-align: start;
}
.textoSecundario{
    color: white;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    text-align: start;
}
.ejeText{
    color: white;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
}

.ejeImg{
    width: 40%;
    margin-bottom: 10px;
}