.projectsBanner{
    width: 100%;
}
.pageTitle{
    color: white;
    font-size: 40px;
    font-family: "Inter";
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.projectsBanner{
    position: relative;
    display: inline-block;
    text-align: center;
}