.quienesSomos{
    background-color: #242626;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.25);
}
.tituloSeccion{
    text-align: start;
    color: #45b5b7;
    border-bottom: 5px solid #45b5b7;
    display: inline;
    font-family: "Inter", sans-serif;
    font-size: 26px;
    font-weight: 700;
}
.descQuienes{
    color: white;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 300;
}
.contentContainer{
    width: 60%;
    display: flex;  
    flex-direction: column;   
}

.textContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.noPadding{
    padding: 0px !important;
}
.rowCont{
    width: 100% !important;
    margin: 0px !important;
}

@media screen and (max-width: 768px){
.contentContainer{
    margin-top: 30px;
    margin-bottom: 30px;
}
}
