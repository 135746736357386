.equipo {
   padding-top:30px
}

.tituloSecEquipo {
    color: #45b5b7;
    font-family: "Inter";
    font-weight: 700;
    font-size: 26px;
    border-bottom: 5px solid #45b5b7;
    display: inline;
}

.tarjetaPer {
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-bottom: 8px solid #45b5b7;
    height: 100%;
}

.fotografia {
    width: 70%;
    margin-top: 10px;
}

.puesto {
    font-family: "Inter" sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin: 0px !important;
}

.nombre {
    font-family: "Inter" sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 0px !important;
}
.somosParte{
    background-color: #45b5b7 !important;
}

.tituloSecSomos{
    color: white;
    font-family: "Inter";
    font-weight: 700;
    font-size: 26px;
    border-bottom: 5px solid white;
    display: inline; 
}

.textoSecSomos{
    color: white;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;  
    text-align: justify;
}
.imgTeam{
    width: 100%;
}
.textEquipoCont{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .tarjetaPer{
        width: 80%;
    }
    .cardsContainer{
        display: flex;
        justify-content: center !important;
        margin-bottom: 30px;
    }
    .textEquipoCont{
        margin-bottom: 40px;
        margin-top: 40px;
    }
    .colEquipo2{
        height: 100%;
    }
}
