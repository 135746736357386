.proyectoFeli {
    background-color: #45B5B7;
}

.proyDesc {
    text-align: justify;
    font-size: 18px;
}

.proyectoCarrera {
    background-color: #242626;
}

.textRight {
    text-align: left;
    color: white;
    font-size: 18px;
}

.logoFeli {
    width: 120px;
    height: auto;
}
.logoAmor {
    width: 200px;
    height: auto;
}
.logoEscalando{
    width: 10%;
}

.titleCont {
    display: flex;
    justify-content: start;
}

.pCContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.videoCarrera {
    width: 100% !important;
    height: auto !important;
}
.videoHome {
    width: 50% !important;
    height: auto !important;
}
.colorV{
    color: #45B5B7;
}

@media screen and (max-width: 768px) {
    .titleCont {
        justify-content: center;
    }

    .textRight {
        text-align: justify;
    }

}