.mision {
    background-color: #45b5b7;
   
}
.cardIcon{
    width: 20%;
}
.cardB{
    border: none;
    border-bottom: 10px solid #4b4b4b;
    height: 100%;
    padding: 10px;
}
.tituloCard{
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #4b4b4b;
}
.descCard{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #4b4b4b;
}
.listContainer{
    display: flex;
    justify-content: center;
}
.lista{
    text-align: start;
    display: inline;
}
@media screen and (max-width: 768px) {
    
    .cardCont{
        margin-bottom: 30px;
    }
    .cardB{
        margin-left: 30px;
        margin-right: 30px;
    }
}
