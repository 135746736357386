.colabImg{
    padding: 15px;
}

.textoAgrad{
    text-align: start;
    font-size: 20px;
}

.tituloBig{
    font-size: 36px !important;
}