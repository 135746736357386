.text-gray-bold{
    color: #5D5D5D;
    font-weight: 700;
    font-family: "Inter", sans-serif;
}

.dataContainer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footerIcon{
    width: 20px;
}

.data{
    margin: 0px;
    margin-left: 10px;
}

.text-gray{
    color: #5D5D5D;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}
.textLeft{
    text-align: left;
}

.formContainer{
    background-color: white;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 10px solid #45b5b7;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}

.submitCnt{
    display: flex;
    justify-content: center;
}

.submitBtn{
    background-color: #45b5b7;
    border: none;
    padding-left: 25px;
    padding-right: 25px;
}

.formTitle{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #45b5b7 !important;
    text-align: left;
}
.mapa{
    width: 100%;
    height: 50%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}

.txtJust{
    text-align: justify;
}