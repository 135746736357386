/*.timeline {
    margin: 5em auto;
    max-width: 34.15em;
}

.checkpoint {
    max-width: 34em;
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;
}

.checkpoint div {
    border: 2px solid #000;
    border-radius: 1em;
    padding: 1.5em;
}

.checkpoint p {
    line-height: 27px;
}

.checkpoint:nth-child(odd) {
    border-left: 3px solid;
    padding-left: 3em;
    transform: translateX(17em);
}

.checkpoint:nth-child(even) {
    border-right: 3px solid #000;
    padding-right: 3em;
    transform: translateX(-17em);
}

.checkpoint:nth-child(odd)::before,
.checkpoint:nth-child(even)::before {
    content: '';
    background: #000;
    width: 3em;
    height: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.checkpoint:nth-child(odd)::before{
    left: 0;
}

.checkpoint:nth-child(even)::before{
    right: 0;
}


                <div className='timeline'>
                    <div className='checkpoint'>
                        <div>
                            <h2>Constitución de Líderes de Altura A.C.</h2>
                            <p>En el estado de Aguascalientes, siendo sus miembros fundadores la Mtra. Viridiana Álvarez Chávez y el Lic. Jorge Luis Álvarez Chávez. Durante varios años desarrollamos proyectos para ayudar a sectores vulnerables, promover valores, el liderazgo transformacional y la participación ciudadana en actividades de desarrollo social.</p>
                        </div>
                    </div>
                    <div className='checkpoint'>
                        <div>
                            <h2>Reestructuración en la Fundación</h2>
                            <p>Se integraron nuevos asociados fortaleciendo el anhelo de transformar vidas de una manera positiva, desarrollando proyectos más estructurados y con una metodología más efectiva. Hemos trabajado de manera ininterrumpida desde nuestra constitución hasta la actualidad.</p>
                        </div>
                    </div>
                </div>*/

.cont {
    margin: 50px;
}

.box {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: start;
}

.box::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 120%;
    background-color: gray;
    left: -15px;
    top: 10px;
}

.F2014::after {
    content: '2014';
    color: white;
    text-align: center;
    font-weight: 700;
    padding-top: 5px;
    position: absolute;
    width: 50px;
    height: 35px;
    border-radius: 10%;
    background-color: #45b5b7;
    left: -28px;
    top: 5px;
}

.F2021::after {
    content: '2021';
    color: white;
    text-align: center;
    font-weight: 700;
    padding-top: 5px;
    position: absolute;
    width: 50px;
    height: 35px;
    border-radius: 10%;
    background-color: #45b5b7;
    left: -28px;
    top: 5px;
}

.F2022::after {
    content: '2022';
    color: white;
    text-align: center;
    font-weight: 700;
    padding-top: 5px;
    position: absolute;
    width: 50px;
    height: 35px;
    border-radius: 10%;
    background-color: #45b5b7;
    left: -28px;
    top: 5px;
}

.F2024::after {
    content: '2024';
    color: white;
    text-align: center;
    font-weight: 700;
    padding-top: 5px;
    position: absolute;
    width: 50px;
    height: 35px;
    border-radius: 10%;
    background-color: #45b5b7;
    left: -28px;
    top: 5px;
}

.fecha {
    color: #45b5b7;
}

.tituloFecha {
    margin-left: 30px;
    color: #4b4b4b;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 26px;
}

.dateDesc {
    color: #5D5D5D;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    text-align: justify;
}
.histRow{
    margin: 0px !important;
}
@media screen and (max-width: 600px) {
    .histRow {
        margin: 0px !important;
    }

    .histCol {
        padding: 0px !important;
    }
}