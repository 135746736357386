.navTrans{
    position: absolute;
    z-index: 100;
    width: 100%;
    border-bottom: 1px solid white;
}

.menuItem{
    color: white !important;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
}

.menuItem:hover{
    color: #9C9C9C !important;
}
.menuIcon{
    width: 70%;
}
.navSocial{
    display: flex;
    justify-content: center;
}
.navSocialBlack{
    display: none;
}
.logoNav{
    width: 100px;
}
@media screen and (max-width: 991px) {
    .menuContainer{
        background-color: white;
        border-radius: 10px;
    }
    .menuItem{
        color: black !important;
    }
    .navSocial{
        display: none;
    }
    .navSocialBlack{
        display: flex;
        justify-content: center;
    }
    .menuIcon{
        width: 100%;
    }
    .menuIconMobile{
        width: 5%;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}

@media screen and (max-width: 500px) {
    .menuIconMobile{
        width: 6%;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}