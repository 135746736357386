.footer{
    background-color: #242626;
    padding-top: 30px;
}
.textoCab{
    color: white;
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    text-align: start;
}
.textoSec{
    color: #a2a2a2;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    text-align: start;
    text-decoration: none;
}
.footerNav{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.listaFooter{
    text-align: left;
    padding: 0px !important;
}
.logo{
    width: 70%;
    margin-bottom: 20px;
}
.socialIcon{
    width: 80%;
}
.socialMedia{
    padding-left: 20px;
    padding-right: 20px;
}
.navNoPad{
    padding-left: 0px !important;
}
.navNoPad:hover{
    color: white;
}
@media screen and (max-width: 768px) {
    .textoCab{
        font-size: 18px;
        text-align: center;
    }
    .textoSec{
        font-size: 18px;
        text-align: center;
    }
    .listaFooter{
        text-align: center;
    }
    .colF{
        margin-bottom: 30px;
    }
    .socialIcon{
        width: 50%;
    }
    .buttonCont{
        justify-content: center;
    }
}