.tituloCarrusel{
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.tituloVer{
    color: #45B5B7;
}

.descripcion{
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
}
.btnGreen{
    background-color: #45b5b7 !important;
    border: none;
    padding-left: 40px;
    padding-right: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.caption{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 60px;
}
.video1{
    width: 50%;
    height: 45%;
}
.banner{
    width: 100%;
    object-fit: contain !important;
    object-position: center center;
}

.carousel-control-prev{
    display: none !important;
}
.carousel-control-next{
    display: none !important;
}

.marcaCont{
    padding: 15px;
    background-color: #45B5B7;
}
.marca{
    font-family: "Inter", sans-serif;
    font-weight: bold;
    color: white;
    margin: 0px;
}
@media screen and (max-width: 1050px) {
    .banner{
        width: fit-content !important;
    }
    .video1{
        width: 70%;
        height: 35%;
    }
}

@media screen and (max-width: 578px) {
    .tituloCarrusel{
        font-size: 50px;
    }
    .video1{
        width: 90%;
        height: 20%;
    }
    .caption{
        justify-content: center;
    }
}
@media screen and (max-width: 420px) {
    .tituloCarrusel{
        font-size: 40px;
    }
}