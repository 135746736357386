.logoApoyar{
    width: 70%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.textGreen{
    color: #45b5b7;
    font-weight: 700;
    font-size: 20px;
}
.datosB{
    font-size: 20px;
    text-align: left;
    font-family: "Inter", sans-serif;
    color: #4b4b4b;
}

.datosTitulo{
    font-size: 30px;
    font-family: "Inter", sans-serif;
    color: #4b4b4b;
    font-weight: 700;
}
.comoApoyarBanner{
    height: 350px;
    background-image: url("../../imagenes/ApoyarBanner.jpg");
    background-repeat: no-repeat;
    background-position: top center;
}

.txtAgrad{
    font-family: "Inter", sans-serif;
    font-size: 22px;
    color: #4b4b4b;
    text-align: justify;
    font-weight: 500;
}
.txtAgrad1{
    font-family: "Inter", sans-serif;
    font-size: 22px;
    color: #4b4b4b;
    text-align: center;
    font-weight: 500;
}